export interface Props {
  title: string
  subTitle?: string
}

export const useHeading = (props: Props) => {
  const { title, subTitle } = props

  return {
    title,
    subTitle,
  }
}

import { FC } from 'react';
import { useHeading, Props } from './useHeading';
import styles from './Heading.module.css';

export const Heading: FC<Props> = (props) => {
  const { title, subTitle } = useHeading(props);

  return (
    <>
      <h2 className={styles.title}>{title}</h2>
      {subTitle && <div className={styles.subTitle}>{subTitle}</div>}
    </>);

};